import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnyModelStr } from '../types/any-models.types';
import { MenteeRelation } from '../types/mentee-relations.types';
@Injectable({
  providedIn: 'root',
})
export class MenteeRelationService {
  constructor(private http: HttpClient) {}

  public addNewRelation(newPair) {
    return this.http.post<MenteeRelation>(environment.apiUrl + `/mentee_relation/`, newPair);
  }

  public getMenteeRelation(mr_id: string) {
    return this.http.get<MenteeRelation>(environment.apiUrl + `/mentee_relation/` + mr_id + '/');
  }

  public getMenteeRelations(instanceId: string, type: AnyModelStr) {
    if (instanceId) {
      return this.http.get<MenteeRelation[]>(environment.apiUrl + `/mentee_relation/`, {
        params: {
          id: instanceId,
          type,
        },
      });
    }
  }

  public updateMenteeRelation(menteeRelation: Partial<MenteeRelation>): Observable<MenteeRelation> {
    return this.http.patch<MenteeRelation>(environment.apiUrl + `/mentee_relation/` + menteeRelation.id + '/', menteeRelation);
  }
}
